export default class CategoriesPostModel {
    constructor(item) {
        this._setName(item);
        this._setNameRu(item);
        this._setUrl(item);
        this._setImage(item);
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }
       /**
     * set url
     * @param url
     * @private
     */
       _setUrl({url}) {
        this.url = url;
    }
    /**
     * set name ru
     * @param name_ru
     * @private
     */
    _setNameRu({name_ru}) {
        this.name_ru = name_ru;
    }
    /**
     * set photo
     * @param image
     * @private
     */
    _setImage({image}) {
        this.photo = image;
    }

}
